import { differenceInMinutes } from "date-fns";
import { useState } from "react";
import { useAlertWidgetTemporaryContext } from "../../../../../contexts/AlertWidgetTemporaryContext";
import { usePrepStationContext } from "../../../../../contexts/PrepStationContext";
import {
  KdsTicket,
  useUpdateTicketOrderStatusMutation,
} from "../../../../../graphql/generated";
import { ErrorAlertWidget } from "../../v2/AlertWidget/ErrorAlertWidget";
import { TicketHeader } from "./TicketHeader";
import { TicketScannedHeader } from "./TicketScannedHeader";
import { TicketScheduledHeader } from "./TicketScheduledHeader";

type TicketHeaderControllerProps = {
  ticket: KdsTicket;
  lineItemStatusUpdateLoading: boolean;
  showScannedHeader: boolean;
};

export const TicketHeaderController = ({
  ticket,
  lineItemStatusUpdateLoading,
  showScannedHeader,
}: TicketHeaderControllerProps) => {
  const backgroundColor = getTicketHeaderColor(
    ticket.cooking_at,
    ticket.status ?? "",
    allItemsReceived(ticket?.line_items),
  );

  const { isExpo } = usePrepStationContext();

  const displaySingleStationBadge = ticket.is_single_station_ticket;

  const [updateOrderStatus] = useUpdateTicketOrderStatusMutation();
  const [showCheckMark, setShowCheckMark] = useState(false);
  const [animateCheckMark, setAnimateCheckMark] = useState(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const { setIsAlertWidgetTemporaryVisible, setTemporaryAlertMessage } =
    useAlertWidgetTemporaryContext();

  const onHeaderClick = async () => {
    const shouldAnimate = allItemsReceived(ticket?.line_items);
    if (isExpo && !lineItemStatusUpdateLoading) {
      if (shouldAnimate) {
        setShowCheckMark(true);
        setTimeout(() => {
          setAnimateCheckMark(true);
        }, 50);
        setTimeout(() => {
          setShowCheckMark(false);
          setAnimateCheckMark(false);
          setShowLoadingSpinner(true);
        }, 800);
        setTimeout(() => {
          updateOrderStatus({
            variables: {
              id: ticket.id,
              status: nextStatus(ticket?.status ?? ""),
            },
          });
        }, 250);
      } else {
        setIsAlertWidgetTemporaryVisible(true);
        setTemporaryAlertMessage(
          <ErrorAlertWidget message={"All items must be marked as received"} />,
        );
        setTimeout(() => {
          setTemporaryAlertMessage("");
          setIsAlertWidgetTemporaryVisible(false);
        }, 3000);
      }
    }
  };

  return (
    <>
      {ticket?.status === "SCHEDULED" ? (
        <TicketScheduledHeader
          name={ticket.customer_name ?? ""}
          pickupTime={ticket.pickup_time}
          status={ticket.status ?? ""}
          store_order_id={ticket?.id ?? 0}
        />
      ) : showScannedHeader ? (
        <TicketScannedHeader />
      ) : (
        <TicketHeader
          ticketCounter={ticket.ticket_counter ?? 0}
          name={ticket.customer_name ?? ""}
          cookingAtTime={ticket.cooking_at}
          status={ticket.status ?? ""}
          table_number={ticket.table_number ?? null}
          headerCSS={backgroundColor}
          onHeaderClick={onHeaderClick}
          showLoadingSpinner={showLoadingSpinner}
          showCheckMark={showCheckMark}
          animateCheckMark={animateCheckMark}
          displaySingleStationBadge={displaySingleStationBadge || false}
          displayTableNumber={isExpo && !!ticket.table_number}
        />
      )}
    </>
  );
};

export const getTicketHeaderColor = (
  cookingAt: Date,
  status: string,
  allItemsReceived: boolean,
) => {
  if (status === "READY" || allItemsReceived) {
    return "bg-lfg-green text-white animate-pulse";
  }
  if (!cookingAt) {
    return "bg-gray-800 text-white";
  }
  const now = new Date();
  const then = new Date(cookingAt);
  let minutesDiff = differenceInMinutes(now, then);

  if (minutesDiff >= 10) {
    return "bg-[#FE4747]";
  } else if (minutesDiff >= 5) {
    return "bg-[#FECB47]";
  }
  return "bg-gray-800 text-white";
};

const nextStatus = (status: string) => {
  switch (status) {
    case "COOKING":
      return "READY";
    case "READY":
      return "COOKING";
    default:
      return "READY";
  }
};

const allItemsReceived = (line_items: any) => {
  for (const lineItem of line_items) {
    if (lineItem.status !== "RECEIVED") {
      return false;
    }
    if (lineItem.sub_line_items && lineItem.sub_line_items.length > 0) {
      for (const subLineItem of lineItem.sub_line_items) {
        if (subLineItem.mark_received && subLineItem.status !== "RECEIVED") {
          return false;
        }
      }
    }
  }
  return true;
};
