import { useHistory } from "react-router-dom";
import { Location } from "../../types";

interface LocationChooserProps {
  locations: Location[];
  updateLocationAccount: (location_id: number) => Promise<void>;
}

const LocationChooser = ({
  locations,
  updateLocationAccount,
}: LocationChooserProps) => {
  const history = useHistory();

  return (
    <div className="flex flex-col overflow-y-scroll h-max gap-4 justify-center items-center p-8 bg-slate-200">
      {locations.map((location) => (
        <button
          key={location.slug}
          className="text-2xl w-1/4 p-4 flex items-center justify-center border border-transparent font-medium rounded-md text-white bg-purple-500"
          onClick={async () => {
            await updateLocationAccount(location.id);
          }}
        >
          {location.name}
        </button>
      ))}
    </div>
  );
};

export default LocationChooser;
