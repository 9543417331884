import { datadogLogs } from "@datadog/browser-logs";
import { useAuth } from "@localkitchens/passwordless-auth";
import * as Sentry from "@sentry/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useAppMetadataContext } from "./contexts/AppMetadataContext";
import { useLocationContext } from "./contexts/LocationContext";
import { KitchenDisplaySystemController } from "./features/KitchenDisplaySystem";
import { useLocationAccount } from "./features/KitchenDisplaySystem/hooks/useLocationAccount";
import LocationChooserController from "./features/Location/LocationChooserController";
import { Logout } from "./features/Logout";
import { Settings } from "./features/Settings";
import { TrackState } from "./index";
import { LocationAccount } from "./types";
import { useSegment } from "./utils/hooks/useSegment";

interface AppProps {
  setTrackingHandler: Dispatch<SetStateAction<TrackState>>;
}

const App = ({ setTrackingHandler }: AppProps) => {
  const [currentVersion, setCurrentVersion] = useState<string | undefined>(
    undefined,
  );
  const {
    slug: currentLocation,
    id: locationId,
    name: locationName,
    updateLocation: setCurrentLocation,
  } = useLocationContext();

  const { track, identify } = useSegment();

  const { user, isAuthenticated, isLoading, login, logout } = useAuth();
  const { setIsAdmin } = useAppMetadataContext();

  useEffect(() => {
    setTrackingHandler({ track });
  }, [user, isAuthenticated]);

  useEffect(() => {
    if (user?.email && locationId && locationName && currentLocation) {
      identify({
        email: user.email,
        location: {
          id: locationId,
          name: locationName,
          slug: currentLocation,
        },
        user,
      });

      Sentry.setUser({
        id: user.user_id,
        email: user.email,
        username: user.name,
      });
    }
  }, [user, locationName, locationId, currentLocation]);

  const onLocationChange = (location: string) => {
    setCurrentLocation(location);
  };

  const { data: locationAccountData } = useLocationAccount(user?.email);

  useEffect(() => {
    let roles: string[] = [];
    if (user) {
      roles = user["https://app.localkitchens.co/roles"] || [];
    }

    if (roles.includes("KDS")) {
      const newSlug = locationAccountData?.location_account?.location?.slug;
      onLocationChange(newSlug);
    }
    if (roles.includes("ADMIN")) {
      setIsAdmin(true);
    }
  }, [user, locationAccountData]);

  useEffect(() => {
    const setVersion = async (retry = true) => {
      const res = await fetch("/version.txt");
      if (!res.ok && retry) {
        setVersion(false);
        return;
      } else if (!res.ok) {
        console.error("Failed to fetch version:", JSON.stringify(res));
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      const version = await res.text();
      setCurrentVersion(version);
    };
    setVersion();
    datadogLogs.init({
      clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN || "",
      site: "datadoghq.com",
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      const res = await fetch("/version.txt");
      const newVersion = await res.text();
      if (currentVersion !== newVersion) {
        window.location.reload();
      }
    }, 30 * 1000);
    return () => clearInterval(interval);
  }, [currentVersion]);

  if (isLoading) {
    return (
      <div className="bg-lfg-secondary h-screen">
        <div className="flex h-full flex-col items-center justify-center">
          <div className="mb-24">Loading...</div>
        </div>
        <img
          className="absolute bottom-0 w-full"
          src="https://lfg-landing.s3-us-west-2.amazonaws.com/sf_alt.png"
        />
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <div className="bg-lfg-secondary h-screen">
        <div className="flex h-full flex-col items-center justify-center">
          <button
            className="bg-lfg-primary text-lfg-secondary z-50 mb-24 rounded px-12 py-6 font-bold uppercase"
            onClick={login}
          >
            Login
          </button>
        </div>
        <img
          className="absolute bottom-0 w-full"
          src="https://lfg-landing.s3-us-west-2.amazonaws.com/sf_alt.png"
        />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black">
      <Switch>
        <Route exact path="/">
          <KitchenDisplaySystemController />
        </Route>
        <Route exact path="/settings">
          <Settings />
        </Route>
        <Route exact path="/logout">
          <Logout logout={logout} />
        </Route>
        <Route path="/locations">
          <LocationChooserController
            isManager={true}
            locationAccount={
              {
                ...locationAccountData?.location_account,
                email: user?.email,
              } as LocationAccount
            }
          />
        </Route>
        <Redirect to="/" />
      </Switch>
    </div>
  );
};

export default App;
