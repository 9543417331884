import { PasswordlessAuth } from "@localkitchens/passwordless-auth";
import * as Sentry from "@sentry/react";
import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { ApolloWrapper } from "./app/ApolloWrapper";
import ContextsContainer from "./contexts/ContextsContainer";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./tailwind.output.css";

const authOrigin = process.env.REACT_APP_AUTH_ORIGIN || "";
const googleClientId = process.env.REACT_APP_GOOGLE_OATH_CLIENT_ID || "";
const { identify } = window.analytics;

type TrackingEvent = {
  event: string;
  properties?: object;
};

type TrackState = {
  track: TrackingHandler;
};
type TrackingHandler = (trackingEvent: TrackingEvent) => void;

const track = () => {};

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.rewriteFramesIntegration(),
      Sentry.captureConsoleIntegration({ levels: ["error"] }),
      Sentry.extraErrorDataIntegration(),
      Sentry.contextLinesIntegration(),
      Sentry.httpClientIntegration(),
    ],
    tracesSampleRate: 1,
    release: process.env.REACT_APP_RELEASE,
  });
}

const AppWrapper = () => {
  // Enables receiving a tracking handler from a child component. Tracking
  // requires data from graphql which will not be populated at
  // the time of rendering this component.
  const [tracker, setTrackingHandler] = useState<TrackState>({ track });

  const trackingHandler = useMemo(() => tracker.track, [tracker]);

  useEffect(() => {
    if (
      !window.location.href.includes(".com") &&
      !window.location.href.includes(".dev") &&
      !window.location.href.includes(".kitchen")
    ) {
      const url = window.location.href.replace(".co", ".com");
      window.location.href = url;
    }
  }, []);

  return (
    <BrowserRouter>
      <PasswordlessAuth
        authOrigin={authOrigin}
        googleClientId={googleClientId}
        track={trackingHandler}
        identify={identify}
      >
        <ApolloWrapper>
          <ContextsContainer>
            <App setTrackingHandler={setTrackingHandler} />
          </ContextsContainer>
        </ApolloWrapper>
      </PasswordlessAuth>
    </BrowserRouter>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export type { TrackingEvent, TrackingHandler, TrackState };
